<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <h2 class="font-bold text-3xl mb-4 text-promy-blue-300 sm:text-base">
          Souhaitez-vous vendre <br />
          votre bien ?
        </h2>
        <div class="flex mt-8 space-x-4 items-baseline">
          <div>
            <validation-provider
              class="relative flex flex-col"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="flex flex-wrap gap-4">
                <selectable-button
                  v-for="(solde, index) in soldeList"
                  :key="solde + index"
                  v-model="form.informations.on_sale"
                  :option-name="solde"
                />
              </div>
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </validation-provider>
          </div>
        </div>
        <div>
          <div
            class="font-bold text-3xl mb-4 text-promy-blue-300 sm:text-base mt-8"
          >
            Avez-vous déjà fait estimer ce bien ?
          </div>
          <validation-provider
            class="flex flex-col mb-4"
            name="form.informations.is_estimated"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Oui"
                :value="form.informations.is_estimated"
                @change="change($event)"
              >
                Oui
              </pro-radio>
              <pro-radio
                label="Non"
                :value="form.informations.is_estimated"
                @change="change($event)"
              >
                Non
              </pro-radio>
            </div>
            <div class="w-full">
              <span
                v-show="errors[0]"
                class="mt-1 text-xs text-promy-red-400"
                >{{ errors[0] }}</span
              >
            </div>
          </validation-provider>
        </div>
      </div>
    </template>
    <template #buttons> <Buttons :form="form" :goNext="invalid" /></template>

    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      soldeList: [
        'Oui, dès que possible',
        'Oui, d’ici 3 mois',
        'Oui, d’ici 6 mois',
        'Oui, dans plus de 6 mois',
        'Non, je souhaite juste me renseigner',
      ],
    }
  },
  methods: {
    change(value) {
      this.form.informations.is_estimated = value
    },
  },
}
</script>

<style scoped>
.space-right > button {
  @apply mr-2 mb-2;
}
</style>
